<p-dropdown
  [options]="activeRoles"
  [(ngModel)]="selectedRole"
  (onChange)="onChange($event)"
  [showClear]="false"
  optionLabel="label"
  appendTo="body"
>
  <ng-template pTemplate="dropdownicon"></ng-template>
  <ng-template pTemplate="selectedItem">
    <span [ngClass]="'role-chip ' + selectedRole?.label.toLowerCase()">{{ selectedRole?.label }}</span>
  </ng-template>
  <ng-template let-role pTemplate="item">
    <span [ngClass]="role?.label.toLowerCase()">
      {{ role?.label }}
    </span>
  </ng-template>
</p-dropdown>
