import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  CLIENTPORTAL,
  CLIENTSPOTLIGHT,
  COMMUNITY,
  EVENTPARTNERSHIPS,
  JOBLISTINGS,
  MENTORDATABASE,
  PROFILE,
  RESOURCES,
  TUTORIAL,
} from '../app-routing.module';
import { filter, first, lastValueFrom } from 'rxjs';
import { hasValue } from '../shared/utils';
import { selectLastMentee } from '../state/selectors/mentee.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../state/reducers';

@Component({
  selector: 'fp-iframe-renderer',
  templateUrl: './iframe-renderer.component.html',
  styleUrls: ['./iframe-renderer.component.scss'],
})
export class IframeRendererComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>
  ) {}
  user_role: string;
  mentee;
  iframeSrc: SafeResourceUrl;
  routeSegment: string;
  listOfMenuItems: string[];
  baseUrl = 'https://femmepalette.notion.site/ebd/';

  async ngOnInit() {
    this.user_role = localStorage.getItem('user_role');

    // language
    if (this.user_role === 'ME') {
      const mentee$ = this.store.select(selectLastMentee()).pipe(filter(hasValue), first());
      this.mentee = await lastValueFrom(mentee$);
    }

    this.route.params.subscribe(params => {
      this.routeSegment = params['iframeSrc'];
      let url: string;

      // Check if the route segment exists in the iframeUrlMap
      if (this.iframeUrlMap.hasOwnProperty(this.routeSegment)) {
        const routeData = this.iframeUrlMap[this.routeSegment];
        // Check user role and assign corresponding URL
        switch (this.user_role) {
          case 'ME':
            this.listOfMenuItems = [COMMUNITY, RESOURCES];
            if (this.mentee.language == 2) {
              url = routeData['CZ'] || routeData['default'] || this.DEFAULT_URL;
            } else {
              url = routeData['ME'] || routeData['default'] || this.DEFAULT_URL;
            }
            break;
          case 'MR':
            this.listOfMenuItems = [COMMUNITY, RESOURCES, PROFILE, MENTORDATABASE];

            url = routeData['MR'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'CC':
            url = routeData['CC'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'CH':
            url = routeData['CH'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'LD':
            url = routeData['LD'] || routeData['default'] || this.DEFAULT_URL;
            break;
          default: // Use default URL for unknown user roles
            url = routeData['default'] || this.DEFAULT_URL;
            break;
        }
      } else {
        // Handle case where route segment doesn't exist in iframeUrlMap
        url = this.DEFAULT_URL;
      }
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  ngAfterViewChecked() {
    const resources_element = document.querySelector('a.p-panelmenu-header-action[href="/resources"]');
    if (!this.listOfMenuItems?.includes(this.routeSegment)) {
      if (resources_element) {
        // Remove 'orange-light-background' and 'font-bold' from elements with the 'orange-light-background' class
        this.removeClasses('.p-panelmenu-header.orange-light-background', ['orange-light-background', 'font-bold']);
        // Remove 'p-highlited' and 'font-bold' from elements with the 'p-highlited' class
        this.removeClasses('.p-panelmenu-header.p-highlight', ['p-highlight', 'font-bold']);
        // add highlight to resources menu item
        resources_element.classList.add('orange-light-background', 'font-bold');
      }
    } else {
      this.removeClasses('a.p-panelmenu-header-action[href="/resources"]', ['orange-light-background', 'font-bold']);
    }
  }

  ngOnDestroy(): void {
    this.removeClasses('a.p-panelmenu-header-action[href="/resources"]', ['orange-light-background', 'font-bold']);
  }

  // Function to remove specified classes from elements matching a selector
  removeClasses(selector, classes) {
    document.querySelectorAll(selector).forEach(element => {
      element.classList.remove(...classes);
    });
  }

  iframeUrlMap = {
    'all-resources': {
      default: `${this.baseUrl}7f25625934d94c6e968ad3186698322f`,
      MR: `${this.baseUrl}7f25625934d94c6e968ad3186698322f`,
    },
    alumni: {
      default: `${this.baseUrl}2421495328484d2db0ab5763f8487536`,
      CZ: `${this.baseUrl}13d2c256c0a0805ba1c7cf4e663cdcc5`,
      ME: `${this.baseUrl}2421495328484d2db0ab5763f8487536`,
    },
    b2b: { MR: `${this.baseUrl}45807c5414ac487e8ed0ff1dddafb7c5` },
    books: {
      CZ: `${this.baseUrl}38d459adab6c48a2a0263be304a09202`,
      ME: `${this.baseUrl}3861e5adadd24e058ffaa4301430e224`,
      MR: `${this.baseUrl}3b0c6c76ebea45dca337c22e955d99b7`,
    },
    'case-studies': {
      MR: `${this.baseUrl}79df79156a824e338d92a1d9eaf75649`,
    },
    challenges: {
      MR: `${this.baseUrl}3cf054a7533847ebb023519b6d6236e1`,
    },
    chemistry: {
      CZ: `${this.baseUrl}00f66236cb884436aaee2b0abc742219`,
      ME: `${this.baseUrl}ad31334b12954a60bb0045668fa2f837`,
      MR: `${this.baseUrl}7b2328b5fcc94701b98d940c8901882e`,
    },
    [CLIENTPORTAL]: {
      default: `${this.baseUrl}3de09217a60b4f78961e738c4d73176e`,
    },
    [JOBLISTINGS]: {
      CC: `${this.baseUrl}13c2c256c0a0806188f3c3397fe2fbca`,
    },
    [CLIENTSPOTLIGHT]: {
      CC: `${this.baseUrl}13c2c256c0a080acac61d5d1cbe30df9`,
    },
    [EVENTPARTNERSHIPS]: {
      CC: `${this.baseUrl}13c2c256c0a0807eaf3ef7cae8603c04`,
    },
    communicating: {
      CZ: `${this.baseUrl}491a49d77afb470fa61a4b798f9d80d1`,
      ME: `${this.baseUrl}fc3b3cd10ea742e2b0b44a4895592551`,
      MR: `${this.baseUrl}517b8cbafbaf44779463f8e3a43470e9`,
    },
    [COMMUNITY]: {
      default: `${this.baseUrl}aa16942bd19c4d8db5421ea01cee5804`,
      CZ: `${this.baseUrl}0d0339fbf57148beab145da4c9229380`,
      ME: `${this.baseUrl}aa16942bd19c4d8db5421ea01cee5804`,
      MR: `${this.baseUrl}bf8231a1df0c4ae194dbf7530224540b`,
    },
    discounts: {
      CZ: `${this.baseUrl}af7020eca1d846779dbf8dd71b13ec4b`,
      ME: `${this.baseUrl}d52e2606d9ef4bf1b2dc24bb6953b1c3`,
      MR: `${this.baseUrl}9b49d96715724004a1ae443202aeddf1`,
    },
    empowering: {
      default: `${this.baseUrl}10def82afea64f2aa2ecf1c94b3fc9f9`,
    },
    'program-overview': {
      CZ: `${this.baseUrl}0c9c935b156245e487aedcedd98345f1`,
      MR: `${this.baseUrl}ba9b42148921466c91902a8a69881bca`,
      default: `${this.baseUrl}3953a9b5a49442c89d6694f52ff93745`,
    },
    'question-guide': {
      CZ: `${this.baseUrl}f040b720f9334a6eabb68751e2eb67ac`,
      ME: `${this.baseUrl}84834db8357c4b2fbecdc0710bc60cb2`,
      MR: `${this.baseUrl}88359fdf91c545ed8506a29968deb73b`,
    },
    referral: {
      CZ: `${this.baseUrl}5af52c3b40f146b18b71357ff1f1c148`,
      ME: `${this.baseUrl}d50022f6c7e74767884b9eea2eee8978`,
    },
    [RESOURCES]: {
      default: `${this.baseUrl}ed4f9e9a8f8040d2ae8d09c92f8d58bd`,
      CZ: `${this.baseUrl}eaee12d616f34d6ca7aec1c6268b2e17`,
      ME: `${this.baseUrl}ed4f9e9a8f8040d2ae8d09c92f8d58bd`,
      MR: `${this.baseUrl}907b5af49f6e4d8ab58615061df743ef`,
      CH: `${this.baseUrl}7fad9d41fab646bfa8f8eae056ff1eeb`,
    },
    'smartgoals-tips': {
      default: `${this.baseUrl}e904e1acba3f481c91dbc6e5853553eb`,
      CZ: `${this.baseUrl}b96fca81d43c43c5ba9c1b86d9d7ee76`,
      ME: `${this.baseUrl}e904e1acba3f481c91dbc6e5853553eb`,
      MR: `${this.baseUrl}cdab0a197d514f6aa3aab2d336eee9dc`,
    },
    tips: {
      MR: `${this.baseUrl}ffcaf7f6f8e74c41bb17f185ccc26a8b`,
    },
    training: {
      default: `${this.baseUrl}897c57724e224a6ba5e972daa3e95d6d`,
      CZ: `${this.baseUrl}39f55cb9cb9e4920bc4f51a9af753287`,
      ME: `${this.baseUrl}897c57724e224a6ba5e972daa3e95d6d`,
      MR: `${this.baseUrl}b350ca011d3640b6a33572bdc0b7c14b`,
    },
    trust: {
      CZ: `${this.baseUrl}d1bfebb88def4e76b8f57a325d4ce331`,
      ME: `${this.baseUrl}50fd749593a24ed4b0454bb036387c3e`,
    },
    [TUTORIAL]: {
      default: `${this.baseUrl}f7fafe684bcb4afc9bbb4d7470539f87`,
      CZ: `${this.baseUrl}37cfdd697f3d490e9a1a78c9a8774b1e`,
      ME: `${this.baseUrl}f7fafe684bcb4afc9bbb4d7470539f87`,
      MR: `${this.baseUrl}b350ca011d3640b6a33572bdc0b7c14b`,
      CH: `${this.baseUrl}15f0ca87d43d463ab54846ac6e95c137`,
    },
    webinars: {
      CZ: `${this.baseUrl}8a0cce87cf4743ab84b2b06208a8e26b`,
      ME: `${this.baseUrl}aff3441cf9834f6d9da10f6cbb7426a9`,
      MR: `${this.baseUrl}a91ad837ca194c55b4f33b51315a4771`,
    },
  };

  DEFAULT_URL = `${this.baseUrl}ed4f9e9a8f8040d2ae8d09c92f8d58bd`;
}
