import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { roleMapping, User } from '../../types/user.types';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';

interface Role {
  label: string;
  index: number;
}
@Component({
  selector: 'fp-role-toggler',
  templateUrl: './role-toggler.component.html',
  styleUrl: './role-toggler.component.scss',
})
export class RoleTogglerComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private store: Store<AppState>
  ) {}
  @Input() user_role: string;
  user$ = this.store.select(state => state.settings.user);
  user: User;
  activeRoles: Role[];
  selectedRole: Role;

  async ngOnInit() {
    this.subscribeToUser();
  }
  private subscribeToUser() {
    this.user$.subscribe(user => {
      this.user = user;
      this.activeRoles = this.getActiveRoles(user?.roles);
      this.selectedRole = this.getSelectedRole(user?.roles);
    });
  }

  private getActiveRoles(userRoles: number[]): Role[] {
    return userRoles?.filter(role => role !== 1).map(index => ({ label: roleMapping[index]?.label, index: index }));
  }

  private getSelectedRole(userRoles: number[]): Role | null {
    const userRole = userRoles?.find(role => roleMapping[role]?.code === this.user_role);
    // Match the selected role with the corresponding object in activeRoles
    return this.activeRoles?.find(role => role.label === roleMapping[userRole]?.label) || null;
  }

  onChange(event) {
    let index = event.value.index;
    this.navigationService.changeUser(this.user, roleMapping[index].code);
  }
}
